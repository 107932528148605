import React from "react";
import "./App.css";
import Row from "./Row.js";
import requests from "./requests";
import "./index.css";
import Nav from "./Nav";
import Banner from './Banner.js';

function App() {
  // const [movies, setmovies] = useState([]);
return (
    <div className="App">
        

        <Nav/>
        <Banner/>

        <Row
        title="NETFLIX ORIGINALS"
        fetchUrl={requests.fetchNetflixOriginals}
        isLargeRow={true}
        />
        <Row title="Netflix Original" fetchUrl={requests.fetchNetflixOriginals} />
        {/* <Row title="Trending Now" fetchUrl={requests.fetchTrending} /> */}
        <Row title="Top Rated" fetchUrl={requests.fetchTopRated} />
        <Row title="Action Movies" fetchUrl={requests.fetchActionMovies} />
        <Row title="Comedy Movies" fetchUrl={requests.fetchComedyMovies} />
        <Row title="Horror Movies" fetchUrl={requests.fetchHorrorMovies} />
        <Row title="Romance Movies" fetchUrl={requests.fetchRomanceMovies} />
        
    </div>
);
}
export default App;
