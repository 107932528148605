const API_KEY ="0348845418f3dd3b62fb9ac460a4fac8";

const requests = {
    fetchTrending:`/trending/movie/week?api_key=${API_KEY}`,
    fetchTopRated:`/movie/top_rated?api_key=${API_KEY}&language=en-US&page=1`,
    fetchNetflixOriginals:`/discover/movie?api_key=${API_KEY}&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1`,
    fetchActionMovies:`/discover/movie?api_key=${API_KEY}&sort_by=revenue.desc&page=1&with_genres=28`,
    fetchComedyMovies:`/discover/movie?api_key=${API_KEY}&sort_by=revenue.desc&page=1&with_genres=35`,
    fetchHorrorMovies:`/discover/movie?api_key=${API_KEY}&sort_by=revenue.desc&page=1&with_genres=27`,
    fetchRomanceMovies:`/discover/movie?api_key=${API_KEY}&sort_by=revenue.desc&page=1&with_genres=10749`,
    fetchDocumentaries:`/discover/movie?api_key=${API_KEY}&sort_by=revenue.desc&page=1&with_genres=99`,
}

export default requests;
































































































